@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 6;
  }
}

.animated-text {
  animation: slideIn 3s ease-out;
}



