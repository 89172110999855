@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


::-webkit-scrollbar {
    max-width: 3px;
  }

.no-number-input::-webkit-inner-spin-button,
  .no-number-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
h1, h2, a {
  font-family: 'Montserrat', sans-serif;
}
p, label, input, span {
  font-family: 'Lato', sans-serif;
}


/* styles.css */

/* styles.css */
